/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import "./Browse.css";
import { Helmet } from "react-helmet-async";
import CountryInfo from "../CountryInformation/CountryInfo";
import Facts from "../Facts/Facts";

const countryCodes = {
  Afghanistan: "af",
  Albania: "al",
  Algeria: "dz",
  Andorra: "ad",
  Angola: "ao",
  "Antigua and Barbuda": "ag",
  Argentina: "ar",
  Armenia: "am",
  Australia: "au",
  Austria: "at",
  Azerbaijan: "az",
  Bahamas: "bs",
  Bahrain: "bh",
  Bangladesh: "bd",
  Barbados: "bb",
  Belarus: "by",
  Belgium: "be",
  Belize: "bz",
  Benin: "bj",
  Bhutan: "bt",
  Bolivia: "bo",
  "Bosnia and Herzegovina": "ba",
  Botswana: "bw",
  Brazil: "br",
  "Brunei Darussalam": "bn",
  Bulgaria: "bg",
  "Burkina Faso": "bf",
  Burundi: "bi",
  "Cabo Verde": "cv",
  Cambodia: "kh",
  Cameroon: "cm",
  Canada: "ca",
  "Central African Republic": "cf",
  Chad: "td",
  Chile: "cl",
  China: "cn",
  "People's Republic of China": "cn",
  Colombia: "co",
  Comoros: "km",
  "Congo (Congo-Brazzaville)": "cg",
  "Congo (Democratic Republic)": "cd",
  "Costa Rica": "cr",
  Croatia: "hr",
  Cuba: "cu",
  Curaçao: "cw",
  Cyprus: "cy",
  "Czech Republic": "cz",
  Denmark: "dk",
  Djibouti: "dj",
  Dominica: "dm",
  "Dominican Republic": "do",
  Ecuador: "ec",
  Egypt: "eg",
  "El Salvador": "sv",
  "Equatorial Guinea": "gq",
  Eritrea: "er",
  Estonia: "ee",
  Eswatini: "sz",
  Ethiopia: "et",
  Fiji: "fj",
  Finland: "fi",
  France: "fr",
  Gabon: "ga",
  Gambia: "gm",
  Georgia: "ge",
  Germany: "de",
  Ghana: "gh",
  Greece: "gr",
  Greenland: "gl", // Added Greenland
  Grenada: "gd",
  Guatemala: "gt",
  Guinea: "gn",
  "Guinea-Bissau": "gw",
  Guyana: "gy",
  Haiti: "ht",
  Honduras: "hn",
  Hungary: "hu",
  Iceland: "is",
  India: "in",
  Indonesia: "id",
  Iran: "ir",
  Iraq: "iq",
  Ireland: "ie",
  Israel: "il",
  Italy: "it",
  Jamaica: "jm",
  Japan: "jp",
  Jordan: "jo",
  Kazakhstan: "kz",
  Kenya: "ke",
  Kiribati: "ki",
  Kuwait: "kw",
  Kyrgyzstan: "kg",
  Laos: "la",
  Latvia: "lv",
  Lebanon: "lb",
  Lesotho: "ls",
  Liberia: "lr",
  Libya: "ly",
  Liechtenstein: "li",
  Lithuania: "lt",
  Luxembourg: "lu",
  Madagascar: "mg",
  Malawi: "mw",
  Malaysia: "my",
  Maldives: "mv",
  Mali: "ml",
  Malta: "mt",
  "Marshall Islands": "mh",
  Mauritania: "mr",
  Mauritius: "mu",
  Mexico: "mx",
  Micronesia: "fm",
  Moldova: "md",
  Monaco: "mc",
  Mongolia: "mn",
  Montenegro: "me",
  Morocco: "ma",
  Mozambique: "mz",
  Myanmar: "mm",
  Namibia: "na",
  Nauru: "nr",
  Nepal: "np",
  Netherlands: "nl",
  "New Zealand": "nz",
  Nicaragua: "ni",
  Niger: "ne",
  Nigeria: "ng",
  "North Korea": "kp",
  "North Macedonia": "mk",
  Norway: "no",
  Oman: "om",
  Pakistan: "pk",
  Palau: "pw",
  Panama: "pa",
  "Papua New Guinea": "pg",
  Paraguay: "py",
  Peru: "pe",
  Philippines: "ph",
  Poland: "pl",
  Portugal: "pt",
  Qatar: "qa",
  Romania: "ro",
  Russia: "ru",
  Rwanda: "rw",
  "Saint Kitts and Nevis": "kn",
  "Saint Lucia": "lc",
  "Saint Vincent and the Grenadines": "vc",
  Samoa: "ws",
  "San Marino": "sm",
  "Sao Tome and Principe": "st",
  "Saudi Arabia": "sa",
  Senegal: "sn",
  Serbia: "rs",
  Seychelles: "sc",
  "Sierra Leone": "sl",
  Singapore: "sg",
  Slovakia: "sk",
  Slovenia: "si",
  "Solomon Islands": "sb",
  Somalia: "so",
  "South Africa": "za",
  "South Korea": "kr",
  "South Sudan": "ss",
  Spain: "es",
  "Sri Lanka": "lk",
  Sudan: "sd",
  Suriname: "sr",
  Sweden: "se",
  Switzerland: "ch",
  Syria: "sy",
  Taiwan: "tw",
  Tajikistan: "tj",
  Tanzania: "tz",
  Thailand: "th",
  "Timor-Leste": "tl",
  Togo: "tg",
  Tonga: "to",
  "Trinidad and Tobago": "tt",
  Tunisia: "tn",
  Turkey: "tr",
  Turkmenistan: "tm",
  Tuvalu: "tv",
  Uganda: "ug",
  Ukraine: "ua",
  "United Arab Emirates": "ae",
  "United Kingdom": "gb",
  "United States": "us",
  Uruguay: "uy",
  Uzbekistan: "uz",
  Vanuatu: "vu",
  "Vatican City": "va",
  Venezuela: "ve",
  Vietnam: "vn",
  Yemen: "ye",
  Zambia: "zm",
  Zimbabwe: "zw",
  Palestine: "ps",
  "Western Sahara": "eh",
  "Hong Kong": "hk",
  Macau: "mo",
  "Faroe Islands": "fo",
  "Puerto Rico": "pr",
  Kosovo: "xk",
  Montserrat: "ms",
  Bermuda: "bm",
  Guam: "gu",
  "Falkland Islands": "fk",
  "French Polynesia": "pf",
  "New Caledonia": "nc",
  "Saint Pierre and Miquelon": "pm",
  Gibraltar: "gi",
};

const tagThumbnails = {
  Weather: "/Browse-Assets/Weather.webp",
};

const Browse = () => {
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [features, setFeatures] = useState([]);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [liveViewers, setLiveViewers] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [tagFeatureCounts, setTagFeatureCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [featuresLoading, setFeaturesLoading] = useState(false);
  const [isCountryView, setIsCountryView] = useState(true); // Default view is "Countries"
  const navigate = useNavigate();
  const { tag: paramTag, videoId: paramVideoId } = useParams();
  const [isMiniPlayerActive, setIsMiniPlayerActive] = useState(false);
  const [miniPlayerFeature, setMiniPlayerFeature] = useState(null);
  const apiKey = process.env.REACT_APP_EARF_API_KEY;
  const [isLoaded, setIsLoaded] = useState(false); // Track image loading state
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sortOption, setSortOption] = useState("liveStreams");
  const [tagsLoading, setTagsLoading] = useState(false);
  const [alerts, setAlerts] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial state

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchFeaturesAndTags = async () => {
      try {
        setIsLoading(true); // Show loader for features
        setTagsLoading(true); // Show loader for tags

        // Fetch live and browse camera data in parallel
        const [liveCamerasResponse, browseCamerasResponse] = await Promise.all([
          fetch("https://server.earf.tv/live-cameras", {
            method: "POST",
            headers: {
              "x-api-key": apiKey,
              "Content-Type": "application/json",
            },
          }),
          fetch("https://server.earf.tv/browse-cameras", {
            method: "POST",
            headers: {
              "x-api-key": apiKey,
              "Content-Type": "application/json",
            },
          }),
        ]);

        if (!liveCamerasResponse.ok || !browseCamerasResponse.ok) {
          throw new Error("Failed to fetch feature data.");
        }

        const liveCamerasData = await liveCamerasResponse.json();
        const browseCamerasData = await browseCamerasResponse.json();

        const onlineLiveCameras = liveCamerasData.features.filter(
          (feature) => feature.properties?.status === "online"
        );
        const onlineBrowseCameras = browseCamerasData.features.filter(
          (feature) => feature.properties?.status === "online"
        );

        const allFeatures = [...onlineLiveCameras, ...onlineBrowseCameras];
        setFeatures(allFeatures);

        const uniqueTags = new Set();
        const tagCounts = {};

        allFeatures.forEach((feature) => {
          ["tag1", "tag2"].forEach((tagKey) => {
            const tag = feature.properties?.[tagKey];
            if (tag) {
              uniqueTags.add(tag);
              tagCounts[tag] = (tagCounts[tag] || 0) + 1;
            }
          });
        });

        // Convert unique tags to an array and apply sorting
        let sortedTags = Array.from(uniqueTags);
        if (sortOption === "liveStreams") {
          sortedTags.sort((a, b) => (tagCounts[b] || 0) - (tagCounts[a] || 0)); // Descending by count
        } else if (sortOption === "alphabetical") {
          sortedTags.sort((a, b) => a.localeCompare(b)); // Alphabetical
        }

        setTags(sortedTags); // Set sorted tags
        setTagFeatureCounts(tagCounts);

        // Filter tags based on current view (countries/categories)
        const filteredTags = isCountryView
          ? sortedTags.filter((tag) => countryCodes.hasOwnProperty(tag)) // Only countries
          : sortedTags.filter((tag) => !countryCodes.hasOwnProperty(tag)); // Only categories

        setFilteredTags(filteredTags); // Set filtered and sorted tags
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setIsLoading(false); // Hide loader for features
        setTagsLoading(false); // Hide loader for tags
      }
    };

    fetchFeaturesAndTags();
  }, [apiKey, sortOption, isCountryView]);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await fetch(
          `https://server.earf.tv/country-profiles`,
          {
            headers: { "x-api-key": apiKey },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch country profiles");

        const data = await response.json();
        const alertsData = {};
        data.features.forEach((profile) => {
          if (profile.properties.alert) {
            alertsData[profile.properties.name] = profile.properties.alert;
          }
        });
        setAlerts(alertsData);
      } catch (error) {
        console.error("Error fetching alerts:", error.message);
      }
    };

    fetchAlerts();
  }, [apiKey]);

  // Helper to split array into batches
  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  useEffect(() => {
    const fetchVideoDetails = async (filtered) => {
      const liveViewersCounts = {};
      const availableStreams = [];

      // Extract video IDs
      const videoIds = filtered.map((feature) => feature.properties.videoId);

      // Split video IDs into batches of 50
      const batches = chunkArray(videoIds, 50);

      await Promise.all(
        batches.map(async (batch) => {
          try {
            // Fetch details for the batch
            const url = `https://server.earf.tv/live-cameras/live-viewers`;
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-api-key": process.env.REACT_APP_EARF_API_KEY,
              },
              body: JSON.stringify({ videoIds: batch }), // Pass batch of videoIds
            });

            const data = await response.json();

            if (response.ok && Array.isArray(data)) {
              data.forEach((videoData) => {
                const { videoId, broadcastContent, liveViewers } = videoData;
                const feature = filtered.find(
                  (f) => f.properties.videoId === videoId
                );

                if (
                  feature &&
                  (broadcastContent === "live" ||
                    broadcastContent === "upcoming")
                ) {
                  availableStreams.push(feature);

                  if (liveViewers) {
                    liveViewersCounts[videoId] = parseInt(liveViewers, 10);
                  }
                }
              });
            }
          } catch (error) {
            console.error("Error fetching batch video details:", error);
          }
        })
      );

      // Sort streams by live viewers count
      const sortedFiltered = availableStreams.sort((a, b) => {
        const viewersA = liveViewersCounts[a.properties.videoId] || 0;
        const viewersB = liveViewersCounts[b.properties.videoId] || 0;
        return viewersB - viewersA;
      });

      setLiveViewers(liveViewersCounts); // Update live viewers
      return sortedFiltered; // Return the sorted list
    };

    const handleTagSelection = async () => {
      if (paramTag) {
        const decodedTag = decodeURIComponent(paramTag).replace(/-/g, " ");
        setIsLoading(true); // Start the loading process

        try {
          // Determine the tag type (tag1 for categories, tag2 for countries)
          const tagType = countryCodes[decodedTag] ? "tag2" : "tag1";

          // Fetch filtered data from the /by-tag API
          const response = await fetch(
            "https://server.earf.tv/live-cameras/by-tag",
            {
              method: "POST",
              headers: {
                "x-api-key": process.env.REACT_APP_EARF_API_KEY,
                "Content-Type": "application/json",
                "X-Request-Source": "LIve-Camera-By-Tag",
              },
              body: JSON.stringify({ tag: decodedTag, tagType }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch data for the selected tag.");
          }

          const geojson = await response.json();

          // If no matching features are found, navigate to 404
          if (!geojson.features || geojson.features.length === 0) {
            navigate("/404");
            return;
          }

          setSelectedTag(decodedTag); // Set the selected tag
          setSelectedCountry(countryCodes[decodedTag] ? decodedTag : null); // Set country name if it's a country

          const sortedFiltered = await fetchVideoDetails(geojson.features); // Fetch video details and sort
          setFilteredFeatures(sortedFiltered); // Update the filtered features after sorting
        } catch (error) {
          console.error(
            "Error fetching data for the selected tag:",
            error.message
          );
          navigate("/404"); // Navigate to 404 on error
        } finally {
          setIsLoading(false); // End the loading process
        }
      } else {
        // Clear the selected tag and reset the feature list when navigating back to /browse
        setIsLoading(true); // Start loading while resetting
        setSelectedTag(null);
        setSelectedCountry(null);
        setFilteredFeatures(features);
        setIsLoading(false); // End the loading process
      }
    };

    handleTagSelection(); // Invoke the handler
  }, [paramTag, features, navigate]);

  const handleTagClick = async (tag, event, navigateToTag = true) => {
    if (event && (event.ctrlKey || event.metaKey || event.button === 1)) {
      return;
    }

    if (event) event.preventDefault();

    const normalizedTag = tag.toLowerCase();
    const matchingTag = tags.find((t) => t.toLowerCase() === normalizedTag);

    if (!matchingTag) {
      navigate("/404");
      return;
    }

    setSelectedTag(tag);

    const tagType = countryCodes[tag] ? "tag2" : "tag1";

    if (navigateToTag) {
      const formattedTag = matchingTag.replace(/\s+/g, "-");
      navigate(`/browse/${encodeURIComponent(formattedTag)}`);
    }

    setFeaturesLoading(true);

    try {
      // Fetch filtered data from /by-tag API
      const response = await fetch(
        "https://server.earf.tv/live-cameras/by-tag",
        {
          method: "POST",
          headers: {
            "x-api-key": process.env.REACT_APP_EARF_API_KEY,
            "Content-Type": "application/json",
            "X-Request-Source": "LIve-Camera-By-Tag",
          },
          body: JSON.stringify({ tag: matchingTag, tagType }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch filtered features.");
      }

      const geojson = await response.json();

      if (geojson.features.length === 0) {
        navigate("/404");
        return;
      }

      const liveViewersCounts = {};
      const availableStreams = [];

      const chunkArray = (array, size) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += size) {
          chunks.push(array.slice(i, i + size));
        }
        return chunks;
      };

      const videoIds = geojson.features.map(
        (feature) => feature.properties.videoId
      );
      const batches = chunkArray(videoIds, 50);

      // Fetch live viewers for the filtered features
      await Promise.all(
        batches.map(async (batch) => {
          try {
            const response = await fetch(
              "https://server.earf.tv/live-cameras/live-viewers",
              {
                method: "POST",
                headers: {
                  "x-api-key": process.env.REACT_APP_EARF_API_KEY,
                  "Content-Type": "application/json",
                  "X-Request-Source": "LIve-Camera-By-Tag",
                },
                body: JSON.stringify({ videoIds: batch }),
              }
            );

            const data = await response.json();

            if (response.ok && Array.isArray(data)) {
              data.forEach((videoData) => {
                const { videoId, broadcastContent, liveViewers } = videoData;
                const feature = geojson.features.find(
                  (f) => f.properties.videoId === videoId
                );

                if (
                  feature &&
                  (broadcastContent === "live" ||
                    broadcastContent === "upcoming")
                ) {
                  availableStreams.push(feature);

                  if (liveViewers) {
                    liveViewersCounts[videoId] = parseInt(liveViewers, 10);
                  }
                }
              });
            }
          } catch (error) {
            console.error("Error fetching batch video details:", error);
          }
        })
      );

      // Wait for all thumbnails to load
      await Promise.all(
        availableStreams.map((stream) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = `https://img.youtube.com/vi/${stream.properties.videoId}/hqdefault.jpg`;
            img.onload = resolve;
            img.onerror = resolve;
          });
        })
      );

      setLiveViewers(liveViewersCounts);

      const sortedFiltered = availableStreams.sort((a, b) => {
        const viewersA = liveViewersCounts[a.properties.videoId] || 0;
        const viewersB = liveViewersCounts[b.properties.videoId] || 0;
        return viewersB - viewersA;
      });

      setFilteredFeatures(sortedFiltered);
    } catch (error) {
      console.error("Error handling tag click:", error.message);
    } finally {
      setFeaturesLoading(false);
    }
  };

  const handleImageLoad = () => {
    setIsLoaded(true); // Set loaded to true when the image is fully loaded
  };

  const handleFeatureClick = (feature, event) => {
    // If Ctrl, Meta, or Middle Mouse Button is pressed, let the browser handle the navigation
    if (event.ctrlKey || event.metaKey || event.button === 1) {
      return;
    }

    // Prevent default navigation behavior for regular clicks
    event.preventDefault();

    setIsMiniPlayerActive(false); // Deactivate mini-player when navigating to a full video
    setMiniPlayerFeature(null); // Clear the mini-player feature

    navigate(
      `/browse/${encodeURIComponent(
        selectedTag.toLowerCase().replace(/\s+/g, "-")
      )}/stream/${feature.properties.videoId}`,
      {
        state: {
          fromBrowse: true,
          tag: selectedTag, // Keep original case for state if needed
          feature: feature,
        },
      }
    );
  };

  const toggleView = (view) => {
    const showCountries = view === "countries";
    setIsCountryView(showCountries);

    // Reset sort option to alphabetical
    setSortOption("liveStreams");

    const sortedTags = showCountries
      ? tags
          .filter((tag) => countryCodes.hasOwnProperty(tag))
          .sort((a, b) => a.localeCompare(b))
      : tags
          .filter((tag) => !countryCodes.hasOwnProperty(tag))
          .sort((a, b) => a.localeCompare(b));

    setFilteredTags(sortedTags);
  };

  return (
    <div className="browse-container">
      <Helmet prioritizeSeoTags>
        {/* Dynamic Page Title */}
        <title>
          {selectedTag
            ? `${selectedTag} Streams | Earf`
            : "Browse Streams | Earf"}
        </title>

        {/* Dynamic Meta Description */}
        <meta
          name="description"
          content={
            selectedTag
              ? `Discover live streams in ${selectedTag} on Earf. Watch and explore unique locations with real-time cameras.`
              : "Browse live streams from around the globe on Earf. Watch real-time views of some of the most fascinating places on Earth."
          }
        />

        {/* Canonical Tag */}
        <link
          rel="canonical"
          href={
            selectedTag
              ? `https://earf.tv/browse/${selectedTag}`
              : "https://earf.tv/browse"
          }
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={
            selectedTag
              ? `${selectedTag} Streams | Earf`
              : "Browse Streams | Earf"
          }
        />
        <meta
          property="og:description"
          content={
            selectedTag
              ? `Watch live streams from ${selectedTag} on Earf. Explore real-time views of various locations and experience the world like never before.`
              : "Browse live streams on Earf and explore real-time views from all over the world. Experience the beauty of Earth’s unique places through live cameras."
          }
        />

        {/* Dynamically change the Open Graph image to use country flags if available */}
        <meta
          property="og:image"
          content={
            selectedTag && countryCodes[selectedTag]
              ? `https://flagcdn.com/w320/${countryCodes[selectedTag]}.png`
              : "https://earf.tv/metaimage.png"
          }
        />
        <meta
          property="og:url"
          content={
            selectedTag
              ? `https://earf.tv/browse/${selectedTag}`
              : "https://earf.tv/browse"
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="929645909242200" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={
            selectedTag
              ? `${selectedTag} Streams | Earf`
              : "Browse Streams | Earf"
          }
        />
        <meta
          name="twitter:description"
          content={
            selectedTag
              ? `Explore live streams from ${selectedTag} on Earf. Watch real-time cameras of amazing places and see the world as it happens.`
              : "Discover live streams from all over the world on Earf. Watch and explore fascinating real-time views of beautiful locations."
          }
        />

        {/* Dynamically change the Twitter image to use country flags if available */}
        <meta
          name="twitter:image"
          content={
            selectedTag && countryCodes[selectedTag]
              ? `https://flagcdn.com/w320/${countryCodes[selectedTag]}.png`
              : "https://earf.tv/metaimage.png"
          }
        />
        <meta name="twitter:image:alt" content="Earf Logo" />
      </Helmet>

      {/* Display the Spinner while loading */}
      {isLoading || featuresLoading || tagsLoading ? (
        <div className="full-container">
          <div className="spinner-wrapper">
            <div className="spinner-content">
              <img
                className="image-spinner"
                src="/metaimage.png"
                alt="Loading"
              />
              <div className="browse-loading-text">
                {selectedTag ? (
                  <>
                    Populating{" "}
                    {countryCodes[selectedTag] && (
                      <img
                        src={`https://flagcdn.com/w40/${countryCodes[selectedTag]}.png`}
                        alt={`${selectedTag} flag`}
                        className="browse-flag-img"
                      />
                    )}{" "}
                    {selectedTag}
                  </>
                ) : (
                  "Browsing"
                )}
              </div>
            </div>
          </div>
          <Facts />

          {/* Loader Footer */}
          <footer className="loader-footer">
            <div className="footer-content">
              <p>
                Is your stream on{" "}
                <Link to="/camera-request" className="earf-highlight">
                  EARF
                </Link>
                ? Want to get verified?{" "}
                <img
                  src="/partner.png"
                  alt="Verified Partner"
                  aria-label="Verified Partner"
                  className="tick-icon"
                />{" "}
              <Link to="/how-to-get-verified" className="loading-footer-link"> Learn How</Link>
              </p>
            </div>
          </footer>
        </div>
      ) : (
        <>
          {/* Render Tag View only when not loading and no tag is selected */}
          {!isLoading && !selectedTag && (
            <div className="tag-view">
              <div className="tag-header">
                <div className="header-row">
                  <h2 className="left-header">
                    Browse{" "}
                    <span style={{ color: "rgba(104, 223, 223, 0.733)" }}>
                      Cameras
                    </span>
                  </h2>
                  <div className="filter-toggle">
                    <span
                      className={`toggle-text ${isCountryView ? "active" : ""}`}
                      onClick={() => toggleView("countries")}
                    >
                      Countries
                    </span>
                    <span
                      className={`toggle-text ${
                        !isCountryView ? "active" : ""
                      }`}
                      onClick={() => toggleView("categories")}
                    >
                      Categories
                    </span>
                  </div>
                </div>
                <div className="description-row">
                  <p className="browse-description">
                    Explore live cameras from all around the world or narrow
                    your view by categories of your choice.
                  </p>
                  <div className="sort-dropdown">
                    <div className="online-streams-count">
                      <div className="green-circle"></div>
                      <span className="stream-count">
                        {features.length} Streams Live
                      </span>
                    </div>
                    <select
                      id="sort-select"
                      className="sort-select"
                      value={sortOption}
                      onChange={(e) => {
                        const newSortOption = e.target.value;
                        setSortOption(newSortOption);
                        if (newSortOption === "alphabetical") {
                          setFilteredTags(
                            [...filteredTags].sort((a, b) => a.localeCompare(b))
                          );
                        } else if (newSortOption === "liveStreams") {
                          setFilteredTags(
                            [...filteredTags].sort(
                              (a, b) =>
                                (tagFeatureCounts[b] || 0) -
                                (tagFeatureCounts[a] || 0)
                            )
                          );
                        }
                      }}
                    >
                      <option value="alphabetical">Alphabetical</option>
                      <option value="liveStreams">Live Streams</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="tag-list-container">
                <div className="tag-list">
                  {filteredTags.map((tag, index) => (
                    <a
                      key={index}
                      href={`/browse/${encodeURIComponent(
                        tag.replace(/\s+/g, "-")
                      )}`}
                      target="_self"
                      className="tag-item"
                      onClick={(event) => handleTagClick(tag, event)}
                    >
                      <div className="tag-thumbnail">
                        <img
                          src={
                            countryCodes[tag]
                              ? `https://flagcdn.com/w320/${countryCodes[tag]}.png`
                              : tagThumbnails[tag] || "/placeholder.webp"
                          }
                          alt={`${tag} thumbnail`}
                          className={`tag-thumbnail-img ${
                            isLoaded ? "loaded" : "loading"
                          }`}
                          loading="lazy"
                          onLoad={handleImageLoad}
                        />
                        {!isLoaded && <div className="image-placeholder"></div>}
                      </div>
                      <div className="tag-info">
                        <div className="tag-name">{tag}</div>
                        <div className="tag-count">
                          {tagFeatureCounts[tag] || 0}{" "}
                          {tagFeatureCounts[tag] === 1 ? "Stream" : "Streams"}
                        </div>
                        {alerts[tag] && (
                          <div className="alert-icon">
                            <i className="fas fa-exclamation-triangle"></i>
                          </div>
                        )}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Render Feature View only when a tag is selected and not loading */}
          {!isLoading && selectedTag && (
            <div className="feature-view">
              <CountryInfo
                countryName={selectedTag}
                countryCode={
                  selectedCountry ? countryCodes[selectedCountry] : null
                }
              />
              <div className="feature-list-container">
                <div className="feature-list">
                  {filteredFeatures.map((feature, index) => (
                    <a
                      key={index}
                      href={`/browse/${encodeURIComponent(
                        selectedTag.toLowerCase().replace(/\s+/g, "-")
                      )}/stream/${feature.properties.videoId}`}
                      target="_self"
                      className="feature-item"
                      onClick={(event) => handleFeatureClick(feature, event)}
                    >
       <div className="feature-thumbnail">
       <img
  src={`https://img.youtube.com/vi/${feature.properties.videoId}/hqdefault.jpg`}
  alt={`${feature.properties.name} thumbnail`}
  className={`feature-thumbnail-img ${isLoaded ? "loaded" : "loading"} ${
    feature.properties.censored === "yes" ? "blurred-thumbnail" : ""
  }`}
  loading="lazy"
  onLoad={handleImageLoad}
/>
{feature.properties.censored === "yes" && (
  <div className="censored-warning">Viewer Discretion Advised</div>
)}
  <div className="live-badge">LIVE</div>
  {liveViewers[feature.properties.videoId] > 0 && (
    <div className="live-viewers">{`${liveViewers[feature.properties.videoId]} watching`}</div>
  )}
</div>

                      <div className="feature-info">
                        <div className="feature-details">
                          <div className="feature-name">
                            {feature.properties.name}
                          </div>
                          <div className="feature-wikidata">
                            {feature.properties.wikidata ||
                              "No Wikidata available"}
                          </div>
                          <div className="channel-name" style={{ display: 'flex', alignItems: 'center' }}>
  <img
    src={feature.properties.channelThumbnail}
    alt={`${feature.properties.channelName} thumbnail`}
    className="channel-thumbnail"
  />
  <span style={{ marginLeft: '5px' }}>{feature.properties.channelName}</span>
  {feature.properties.partner &&
    feature.properties.partner.toUpperCase() === "YES" && (
      <img
        src="/partner.png" // Ensure this path is correct
        alt="Partner icon"
        style={{ marginLeft: "5px", width: "16px", height: "16px" }}
      />
    )}
</div>

                          <div className="feature-tags">
                            {[
                              feature.properties.tag1,
                              feature.properties.tag2,
                            ]
                              .filter((tag) => tag)
                              .map((tag, index) => (
                                <span key={index} className="feature-tag">
                                  {tag}
                                </span>
                              ))}
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Browse;
